import React, { useState, useRef, useCallback } from 'react';
import { Box, Input, Button, Heading, Text } from '@chakra-ui/react';
import { createArticle } from '../services/articleService';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const NewArticleForm = () => {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [author_avatar, setAuthorAvatar] = useState('');
  const [excerpt, setExcerpt] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();
  const quillRef = useRef();

  // Συνάρτηση για το ανέβασμα εικόνων, χρησιμοποιώντας useCallback για σταθερότητα
  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('image', file);

        try {
          const res = await axios.post('http://localhost:5000/upload', formData);
          const url = res.data.url;
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection(true);
          quill.insertEmbed(range.index, 'image', url);
        } catch (err) {
          console.error(err);
          alert('Αποτυχία ανέβασμα εικόνας');
        }
      }
    };
  }, []);

  // Ορισμός των modules του ReactQuill
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: imageHandler, // Χρήση της συνάρτησης imageHandler
      },
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createArticle({
      title,
      author,
      author_avatar,
      excerpt,
      content,
    })
      .then((response) => {
        console.log('Response status:', response.status);
        alert('Το άρθρο δημοσιεύθηκε με επιτυχία');
        navigate('/');
      })
      .catch((error) => {
        console.error('Error:', error.response.data); // Προσθήκη λεπτομερειών σφάλματος
        alert('Προέκυψε σφάλμα κατά τη δημοσίευση του άρθρου');
      });
  };

  return (
    <Box maxWidth="800px" margin="auto" py={12} px={4}>
      <Heading as="h2" size="xl" textAlign="center" mb={6}>
        Νέο Άρθρο
      </Heading>
      <form onSubmit={handleSubmit}>
        <Input
          placeholder="Τίτλος"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          mb={4}
          required
        />
        <Input
          placeholder="Συγγραφέας"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          mb={4}
          required
        />
        <Input
          placeholder="URL Εικόνας Συγγραφέα"
          value={author_avatar}
          onChange={(e) => setAuthorAvatar(e.target.value)}
          mb={4}
        />
        <Input
          placeholder="Περίληψη"
          value={excerpt}
          onChange={(e) => setExcerpt(e.target.value)}
          mb={4}
          required
        />
        <Box mb={4}>
          <ReactQuill
            ref={quillRef}
            value={content}
            onChange={setContent}
            theme="snow"
            modules={modules}
            placeholder="Περιεχόμενο"
            style={{ height: '300px' }}
          />
        </Box>
        <Button type="submit" colorScheme="blue" width="full" mt={4}>
          Δημοσίευση
        </Button>
      </form>
    </Box>
  );
};

export default NewArticleForm;
