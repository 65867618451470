// src/components/AnnouncementsDashboard.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Heading,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import {
  getAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
} from '../services/announcementService';
import { AuthContext } from '../context/AuthContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AnnouncementsDashboard = () => {
  const { user } = useContext(AuthContext);
  const [announcements, setAnnouncements] = useState([]);
  const [formData, setFormData] = useState({ title: '', content: '', file: null });
  const [editingAnnouncement, setEditingAnnouncement] = useState(null);
  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = () => {
    getAnnouncements()
      .then((response) => setAnnouncements(response.data))
      .catch((error) => console.error('Error fetching announcements:', error));
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'file') {
      setFormData({ ...formData, file: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleContentChange = (value) => {
    setFormData({ ...formData, content: value });
  };

  const handleCreateOrUpdate = () => {
    const data = new FormData();
    data.append('title', formData.title);
    data.append('content', formData.content);
    if (formData.file) {
      data.append('file', formData.file);
    }

    if (editingAnnouncement) {
      updateAnnouncement(editingAnnouncement.id, data)
        .then(() => {
          fetchAnnouncements();
          setFormData({ title: '', content: '', file: null });
          setEditingAnnouncement(null);
        })
        .catch((error) => console.error('Error updating announcement:', error));
    } else {
      createAnnouncement(data)
        .then(() => {
          fetchAnnouncements();
          setFormData({ title: '', content: '', file: null });
        })
        .catch((error) => console.error('Error creating announcement:', error));
    }
  };

  const handleEdit = (announcement) => {
    setEditingAnnouncement(announcement);
    setFormData({ title: announcement.title, content: announcement.content, file: null });
  };

  const handleDelete = (id) => {
    if (window.confirm('Είσαι σίγουρος ότι θέλεις να διαγράψεις αυτή την ανακοίνωση;')) {
      deleteAnnouncement(id)
        .then(() => fetchAnnouncements())
        .catch((error) => console.error('Error deleting announcement:', error));
    }
  };

  return (
    <Box bg={bgColor} color={textColor} p={8} borderRadius="lg" boxShadow="xl" maxWidth="800px" margin="auto" my={8}>
      <Heading as="h2" size="xl" mb={6} textAlign="center">
        Διαχείριση Ανακοινώσεων
      </Heading>

      {/* Φόρμα δημιουργίας/επεξεργασίας ανακοίνωσης */}
      <Box mb={8}>
        <Heading as="h3" size="md" mb={4}>
          {editingAnnouncement ? 'Επεξεργασία Ανακοίνωσης' : 'Νέα Ανακοίνωση'}
        </Heading>
        <FormControl mb={4}>
          <FormLabel>Τίτλος</FormLabel>
          <Input
            name="title"
            placeholder="Τίτλος"
            value={formData.title}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Περιεχόμενο</FormLabel>
          <ReactQuill
            value={formData.content}
            onChange={handleContentChange}
            theme="snow"
            placeholder="Περιεχόμενο"
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Συνημμένο Αρχείο (προαιρετικό)</FormLabel>
          <Input
            type="file"
            name="file"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
            onChange={handleInputChange}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={handleCreateOrUpdate} mr={2}>
          {editingAnnouncement ? 'Αποθήκευση' : 'Δημιουργία'}
        </Button>
        {editingAnnouncement && (
          <Button onClick={() => { setEditingAnnouncement(null); setFormData({ title: '', content: '', file: null }); }}>
            Ακύρωση
          </Button>
        )}
      </Box>

      {/* Πίνακας με τις ανακοινώσεις */}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Τίτλος</Th>
            <Th>Ημερομηνία</Th>
            <Th>Ενέργειες</Th>
          </Tr>
        </Thead>
        <Tbody>
          {announcements.map((announcement) => (
            <Tr key={announcement.id}>
              <Td>{announcement.title}</Td>
              <Td>{new Date(announcement.created_at).toLocaleDateString('el-GR')}</Td>
              <Td>
                <Button size="sm" onClick={() => handleEdit(announcement)} mr={2}>
                  Επεξεργασία
                </Button>
                <Button size="sm" colorScheme="red" onClick={() => handleDelete(announcement.id)}>
                  Διαγραφή
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default AnnouncementsDashboard;
