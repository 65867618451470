// frontend/src/components/EditArticleForm.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Input, Button, Heading, Spinner, Text } from '@chakra-ui/react';
import { updateArticle, getArticleById } from '../services/articleService';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const EditArticleForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const quillRef = useRef();

  // Συνάρτηση για το ανέβασμα εικόνων, χρησιμοποιώντας useCallback για σταθερότητα
  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('image', file);

        try {
          const res = await axios.post('http://localhost:5000/upload', formData);
          const url = res.data.url;
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection(true);
          quill.insertEmbed(range.index, 'image', url);
        } catch (err) {
          console.error(err);
          alert('Αποτυχία ανέβασμα εικόνας');
        }
      }
    };
  }, []);

  // Ορισμός των modules του ReactQuill
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: imageHandler, // Χρήση της συνάρτησης imageHandler
      },
    },
  };

  useEffect(() => {
    // Φόρτωση των δεδομένων του άρθρου
    const fetchArticle = async () => {
      try {
        const response = await getArticleById(id);
        setArticle(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        alert('Προέκυψε σφάλμα κατά τη φόρτωση του άρθρου');
        setLoading(false);
      }
    };
    fetchArticle();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle((prevArticle) => ({
      ...prevArticle,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setArticle((prevArticle) => ({
      ...prevArticle,
      content: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateArticle(id, article);
      alert('Το άρθρο ενημερώθηκε επιτυχώς');
      navigate(`/article/${article.slug}`);
    } catch (error) {
      console.error(error);
      alert('Προέκυψε σφάλμα κατά την ενημέρωση του άρθρου');
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" py={12}>
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!article) {
    return (
      <Box textAlign="center" py={12}>
        <Text>Το άρθρο δεν βρέθηκε.</Text>
      </Box>
    );
  }

  return (
    <Box maxWidth="800px" margin="auto" py={12} px={4}>
      <Heading as="h2" size="xl" textAlign="center" mb={6}>
        Επεξεργασία Άρθρου
      </Heading>
      <form onSubmit={handleSubmit}>
        <Input
          name="title"
          placeholder="Τίτλος"
          value={article.title}
          onChange={handleChange}
          mb={4}
          required
        />
        <Input
          name="author"
          placeholder="Συγγραφέας"
          value={article.author}
          onChange={handleChange}
          mb={4}
          required
        />
        <Input
          name="author_avatar"
          placeholder="URL Εικόνας Συγγραφέα"
          value={article.author_avatar || ''}
          onChange={handleChange}
          mb={4}
        />
        <Input
          name="excerpt"
          placeholder="Περίληψη"
          value={article.excerpt}
          onChange={handleChange}
          mb={4}
          required
        />
        <Box mb={4}>
          <ReactQuill
            ref={quillRef}
            value={article.content}
            onChange={handleContentChange}
            theme="snow"
            modules={modules}
            placeholder="Περιεχόμενο"
            style={{ height: '300px' }}
          />
        </Box>
        <Button type="submit" colorScheme="blue" width="full" mt={4}>
          Αποθήκευση Αλλαγών
        </Button>
      </form>
    </Box>
  );
};

export default EditArticleForm;
